<template>
  <div class="game-ended flex flex-column items-center">
    <Button v-if="isAdmin" class="game-ended__dashboard" @click="$router.push('/dashboard')">
      {{ $t('checkout.success.backToDashboard') }}
    </Button>
    <h1 class="mb2 color-white text-shadow center">{{ $t('endResults') }} 🥳 🎉</h1>
    <div class="mb4 px1 color-white text-shadow center">
      {{ $t('endResultsThanks') }}
    </div>
    <slot name="podium" />
    <div class="flex items-end game-ended-podium-wrapper">
      <div class="game-ended-podium flex items-center justify-center" v-for="i in 3" :key="i">
        {{ i }}
      </div>
    </div>
    <Feedback />
    <slot name="rest" />
  </div>
</template>

<script>
  import Feedback from '@/components/common/Feedback.vue';

  export default {
    name: 'FunTeamBiddingGameEnded',
    components: {
      Feedback
    },
    props: {
      results: {
        type: Array,
        default: () => []
      },
      isAdmin: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      sortedResults() {
        return [...this.results].sort((a, b) => b.money - a.money);
      }
    }
  };
</script>

<style scoped lang="scss">
  .game-ended {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    padding: 100px 0;
    background: color(accent);

    &-podium {
      background: white;
      width: 400px;
      color: color(primary);
      box-shadow: global-vars(ui-default-box-shadow--dark);
      @include font(28px, 600);

      @include md-down() {
        width: 250px;
      }

      &-wrapper {
        width: 1200px;

        @include md-down() {
          width: 750px;
        }

        @include mobile() {
          display: none;
        }
      }

      &:first-of-type {
        order: 2;
        height: 200px;
      }
      &:nth-of-type(2) {
        order: 1;
        height: 130px;
      }
      &:nth-of-type(3) {
        order: 3;
        height: 60px;
      }
    }

    &__dashboard {
      position: fixed;
      top: 4px;
      right: global-vars(ui-default-measure5x);
    }
  }
</style>
